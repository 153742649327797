<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('language.all-languages') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <b-overlay :show="showOverlay">
            <div v-show="languages">
              <b-list-group>
                <b-list-group-item v-for="lang in languages" :key="lang.id" :class="'d-flex align-items-center enabled-' + lang.enabled" :to="{ name : 'EditLanguage', params: { languageShortName: lang.shortName }}">
                  <b-avatar variant="primary" :src="lang.icon" class="mr-4"></b-avatar>
                  <h3 class="display-5">{{ lang.name }}</h3>
                  <b-badge></b-badge>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div v-show="!languages">
              <b-alert variant="warning" show>
                <p><BIconExclamationCircleFill /> {{ $t('language.no-languages')}}</p>
                <p><b-link :to="{ name : 'AddLanguage'}">{{ $t('language.add-language') }}</b-link></p>
              </b-alert>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import { BIconExclamationCircleFill, BAvatar, BBadge } from 'bootstrap-vue'

export default {
  name : "AllLanguages",
  components : { NavBar, BAvatar, BIconExclamationCircleFill, BBadge },

  data : function() {
    return {
      languages : [],
      showOverlay: true
    }
  },

  created : function() {
    this.$http.get("/cms/api/languages/all", { headers: {Authorization: this.getAuthData().accessToken }})
      .then(response => {
        //console.log(response);
        this.languages = response.data;
        this.showOverlay = false;
      })
      .catch(error => {
        var message = this.handleError(error);
        this.showOverlay = false;
    })
  }

}
</script>

<style scoped>
  .b-overlay-wrap {min-height: 200px}
</style>
